import localeAntd from "ant-design-vue/lib/locale-provider/ar_EG";

const messages = {
  all: {
    okay: "تأكيد",
    add: "إضافة",
    edit: "تعديل",
    cancel: "إلغاء",
    save: "حفظ",
    sureToCancelAlert: "هل أنت متأكد من الإلغاء؟",
    sureToDelete: "هل أنت متأكد من الحذف؟",
    startDate: "تاريخ البداية",
    endDate: "تاريخ النهاية",
    requiredStartDate: "تاريخ البداية مطلوب",
    requiredEndDate: "تاريخ النهاية مطلوب",
    requiredName: "الإسم مطلوب",
    requiredLevel: "المستوى مطلوب",
    enterInformationBelow: "أدخل المعلومات في الحقول أدناه",
    oui: "نعم",
    non: "لا",
    and: "و",
    filterBy: "بحث عبر",
    filterByAddedDate: "بحث عبر تاريخ الإضافة",
    sureTo: "هل انت متأكد؟",
    unsavedchanges: "تعديلات غير محفوظة!",
    unsavedchangesContent: "هل تريد حقا المغادرة؟ لم تقم بحفظ تعديلاتك!",
    savedChanges: "تم حفظ التعديلات بنجاح",
    typeNeeded: "أنواع الملفات المسموح بها: png و jpg و jpeg.",
    infoConx: "معلومات تسجيل الدخول",
    lienConx: "رابط تسجيل الدخول",
    lienConxText: "أو تطبيق EdTeacher على الهاتف"
  },

  teacherAvailability: {
    available: "متوفر",
    notAvailable: "غير متوفر",
    saved: "تم حفظ التوفر بنجاح",
    notFilledYet: "المعلم لم يقم بملئ جدول التوفر الخاص به بعد",
  },

  smartSchedule: {
    generalParams: "الإعدادات العامة",
    comment: "تعليق",
    commentRequired: "التعليق مطلوب",
    startDay: "يوم البداية",
    endDay: "يوم النهاية",
    workingTime: "وقت العمل",
    breakingTime: "وقت الراحة",
    startAt: "يبدأ",
    endAt: "ينتهي",
    scheduleParmsAdded: "تمت إضافة إعدادات جدول جديدة بنجاح",
    generalParamsSaved: "تم حفظ الإعدادات العامة بنجاح",
    selectSchedule: "إختر الإعدادات",
    subjectsSchedParam: "المواد",
    weeklyRate: "الوقت في الأسبوع",
    module: "وحدة",
    subject: "مادة",
    moduleSpecific: "وحدة نموذجيّة",
    teacherClassParam: "تعيين المعلمين",
    teacherAffectedSuccess: "تم تعيين المعلمين بنجاح",
    teachersWeeklyRate: "الوقت في الأسبوع للمعلمين",
    name: "اسم الإعدادات",
    smartSchedule: "الجدول الذكي",
    smartSchedules: "الجداول الذكية",
    ready: "جاهز",
    pending: "قيد الإنتظار",
    finished: "منتهي",
    error: "خطأ",
    addParam: "إضافة إعدادات",
    migrateSuccess: "تم نقل الجداول الذكيّة بنجاح",
    migrate: "نقل الجداول الذكيّة",
    unmigrated: "تم إعادة استعادة جداول الأوقات بنجاح",
    noDataToUnmigrate: "جداول الأوقات تحتوي بالفعل على جلسات يدوية!",
    unmigrate: "استعادة الجداول اليدويّة",
    minDuration: "اعلى مدة",
    maxDuration: "أقصى المدة",
    fillAllClassroomsToGenerate: "الرجاء ملء جميع الفصول لتوليد جداول الأوقات ",
    weeklyRateMaxError:
      "يجب أن يكون الوقت في الأسبوع مساويًا أو أكبر من الحد الأقصى للمدة",
    weeklyRateMinError:
      "يجب أن يكون الوقت في الأسبوع مساويًا أو أكبر من الحد الأدنى للمدة",
  },

  calender: {
    month: "شهر",
    week: "أسبوع",
    day: "يوم",
    addEvent: "إضافة حدث",
    addEventTitle: "إضافة حدث جديد",
    duration: "المدة",
    durationRequired: "المدة مطلوبة",
    exam: "إمتحان",
    party: "حفلة",
    excursion: "رحلة",
    meeting: "إجتماع",
    other: "أخرى",
    eventType: "نوع الحدث",
    color: "اللون",
    typeRequired: "نوع الحدث مطلوب",
    editEventTitle: "تعديل الحدث",
    editEvent: "تعديل الحدث",
    editedEvent: "تم تعديل الحدث بنجاح",
    deletedEvent: "تم حذف الحدث بنجاح",
    addedEvent: "تم إضافة الحدث بنجاح",
    meetingBetweenTeachersAdvisors: "إجتماع بين المعلمين والمرشدين",
    createdBy: "إنشئ بواسطة المرشد",
  },

  chat: {
    ROOMS_EMPTY: "لا توجد غرف",
    ROOM_EMPTY: "لم يتم اختيار غرفة",
    NEW_MESSAGES: "رسائل جديدة",
    MESSAGE_DELETED: "تم حذف هذه الرسالة",
    MESSAGES_EMPTY: "لا توجد رسائل",
    CONVERSATION_STARTED: "بدأت المحادثة في:",
    TYPE_MESSAGE: "اكتب الرسالة",
    SEARCH: "يبحث",
    IS_ONLINE: "متصل",
    LAST_SEEN: "اخر ظهور ",

    parentDetails: "معلومات الوالدين",
    startNewConversationWithParent: "إنشاء محادثة جديدة مع أحد الأولياء",
    addParent: "إضافة الولي",
    deleted: "تم حذف الرسالة",
  },

  paymentStatus: {
    paymentStatus: "حالة الدفوعات",
    paidAmount: "المبالغ المدفوعة",
    monthRemainingPayments: " المبالغ المتبقيّة لشهر {month}",
    allRemainingPayments: "كل المبالغ المتبقيّة",
    noRemainingPyaments: "لا يوجد مبالغ متبقيّة",
    nothingPaid: "لا يوجد أي مفوعات",
  },

  language: {
    ar: "العربيّة",
    fr: "الفرنسيّة",
    en: "الإنجليزيّة",
  },

  appreciation: {
    adminAppreciation: "ملاحظات الإدارة",
    withOptions: "ملاحظات محددة",
    manual: "ملاحظات يدوية",
    appreciation: "ملاحظات",
    add: "إضافة ملاجظة",
    changedToManual: "تم تغيير الملاحظات إلى الطريقة اليدوية",
    changedToOptions: "تم تغيير الملاحظات إلى الطريقة المخصصة",
    teacherManualDesc: "الأستاذ لديه الإمكانية لكتابة الملاحظات بشكل حر",
    saved: "تم حفظ الملاحظة بنجاح",
    deleted: "تم حذف الملاحظة بنجاح",
  },

  feedback: {
    sendFeedback: "إرسال ملاحظات",
    category: "فئة",
    comment: "تعليق",
    problem: "مشكلة / خطأ",
    idea: "فكرة للتحسين",
    others: "أخرى",
    section: "القسم",
    selectSection: "الرجاء تحديد القسم",
    priority: "أولوية",
    selectPriority: "الرجاء اختيار الأولوية",
    low: "قليلة",
    medium: "متوسطة",
    high: "عالية",
    critical: "شديدة الأهمية",
    message: "رسالة",
    uploadImages: "رفع صور",
    uploadAudio: "رفع مقطع صوتي",
    record: "تسجيل",

    successSent:
      "شكرًا لك على مساهمتك. سنراجعها ونرد عليك إذا لزم الأمر. نتمنى لك يومًا سعيدًا!",
  },

  certification: {
    add: "أضف الشهادة",
    default: "قم بتعيين الشهادات الافتراضية",
    confirmDefault:
      "سيؤدي هذا الإجراء إلى إزالة شهائدك. هل أنت متأكد من تعيينه كافتراضي؟",
    name: "اسم الشهادة",
    min: "أدنى معدل",
    max: "أقصى معدل",
    minError: "لا يمكن أن يكون المعدل الأدنى أكبر من المعدل الأقصى",
    maxError: "لا يمكن أن يكون المعدل القصوى أقل من المعدل الأدنى ",
    defaultDone: "تم تحديث الشهادات بنجاح",
    moyenne: "المعدل",
  },

  decisionConseil: {
    decision: "القرار",
    decisionM: "القرار (ذكر)",
    decisionF: "القرار (أنثى)",
    decisionConseil: "قرارات المجلس",
    default: "قم بتعيين القرارات الإفتراضية",
    defaultDone: "تم تحديث القرارات بنجاح",
    confirmDefault:
      "سيؤدي هذا الإجراء إلى إزالة قراراتك. هل أنت متأكد من تعيينه كافتراضي؟",
  },

  notifications: {
    desactivation: "تم تعليق حسابك! اتصل بالإدارة لمزيد من المعلومات!",
    desactivateBuilding: "تم تعليق مؤسستك! اتصل بالإدارة لمزيد من المعلومات!",
    updateuser: "تم تعديل الحساب! الرجاء إعادة الاتصال!",
    updateAccess: "تم تعديل الصلاحيات! الرجاء إعادة الاتصال!",
  },

  login: {
    incorrectInfo: "إسم المستخدم أو كلمة المرور غير صحيحة",
    bienvenue: "مرحبا بك في",
    title: "نظام إدارة للمدارس",
    connectezVous: "تسجيل الدخول!",
    connextion: "تسجيل الدخول",
    requisMdp: " الرجاء إدخال كلمة المرور الخاصة بك!",
    requisUsername: "الرجاء إدخال اسم المستخدم!",
    username: "اسم المستخدم",
    password: "كلمةالمرور",
    connecte: "تم الإتصال",
    loginSuccess: "لقد قمت بتسجيل الدخول بنجاح",
    readAndAccept: "قرأت وأوافق على",
    termsAndConditions: "الشروط والأحكام",
    subscribePart1: " إذا لم تكن مدرستك مسجلة بعد، فاكتشف عروضنا الحصرية على",
    subscribePart2: " موقعنا",
    subscribePart3: "! وانضم إلى مجتمعنا التعليمي اليوم",
  },

  niveau: {
    niveauPre: "Préscolaire",

    niveau0: "تحضيري",
    niveau1: "سنة أولى",
    niveau2: "سنة ثانية",
    niveau3: "سنة ثالثة",
    niveau4: "سنة رابعة",
    niveau5: "سنة خامسة",
    niveau6: "سنة سادسة",
    autres: "أخرى",
    //college
    niveau7: "سنة سابعة",
    niveau8: "سنة ثامنة",
    niveau9: "سنة تاسعة",
    //lycee
    niveau10: "أولى ثانوي",
    niveau11: "ثانية ثانوي",
    niveau12: "ثالثة ثانوي",
    niveau13: "رابعة ثاتوي",
    autres: "أخرى",
  },

  assiduite: {
    assiduite: "الحضور",
    retard: "متأخر",
    present: "حاضر",
    absence: "غائب",
    assiduiteMSA: "تم تحديث الحضور",
    assiduiteMoisChart: "{className} حضور الفصل هذا الشهر ",
    nb_absent: "عدد الغيابات",
    nb_delay: "عدد التأخيرات",
    date: "التاريخ",
    areYouSureToEdit: "هل أنت متأكد أنك تريد تغيير حالة الحضور؟",
  },

  avance: {
    ajoutAvance: "إضافة دفع مسبق",
    ajoutAvanceSubTitle: ".الرجاء إكمال الحقول أدناه لإضافة دفع مسبق",
    montantAvance: "المبلغ المسبق",
    impoAnnulerAvance: "غير قادر على إلغاء الدفع المسبق",
    typeRecetteRequis: "نوع الدخل مطلوب",
    saisirChequeInfo: "أدخل معلومات الصك",
    saisirChequeEspaceInfo: "أدخل معلومات الصك و النقد",
    chequeNumRequis: "رقم الصك مطلوب",
    historiqueAvances: "سجل المدفوعات المسبقة",
    avanceAjoute: "تمت إضافة الدفع المسبق بنجاح",
  },

  profil: {
    parentSuggestionTitle: "الآباء الذين لديهم نفس رقم الهاتف",
    historique: "السجل",
    paiement: "الدفع",
    payes: "مدفوع",
    nonPayes: "غير مدفوع",
    bulletin: " تقرير المدرسة عن التلميذ",
    qrCode: "رمز الاستجابة السريعة",
    informations: "معلومات",
    Control: "منظومة الولي",
    paiementScol: "دفع الرسوم الدراسية",
    paiementAct: "دفع رسوم الانشطة",
    listeReglements: "قائمة المدفوعات",
    sexe: "الجنس",
    nom: "اللقب ",
    prenom: "الاسم الاول",
    dateNaissance: "تاريخ الميلاد",
    garcon: "ولد",
    fille: "بنت",
    adresse: "عنوان بريدي",
    tel: "هاتف",
    classe: "فصل دراسي",
    notes: "درجات",
    nonInscrit: "غير مسجل بعد",
    nomUser: "إسم المستخدم",
    mdp: "كلمة السر",
    gere: "إدارة",
    consulter: "مراجعة",
    consulterPaiement: "مراجعة الدفع",
    etat: "الحالة",
    mois: "الشهر",
    etablissement: "المؤسسة",
    ajouteLe: "أضيف في",
    lastModifi: "أخر تعديل",
    annulerInscri: "إلغاء الإشتراك",
    changerPrametreParent: "تغيير إعدادات الولي",
    changerPrametreEleve: "تغيير إعدادات التلميذ",
    inscriActivitePour: "{name} تسجيل أنشطة إلى",

    poid: "وزن",
    taille: "الطول",
    maladies: "الأمراض",
    remarques: "ملاحظات",
    selectOption: "يجب إختيار عنصر واحد على الأقل",
    aucuneMaladieEnregistree: "لم يتم تسجيل أي مرض",
    aucuneDossierMedical: "لا توجد سجلات طبية!",

    annulationEleve: "إلغاء تسجيل التلميذ",
    deleteEleve: "حذف التلميذ",
  },
  topBar: {
    issuesHistory: "تاريخ المشاكل",
    projectManagement: "ادارة مشروع",
    typeToSearch: "بحث",
    findPages: "جاري البحث عن صفحات ...",
    actions: "الأفعال",
    status: "الحالة",
    profileMenu: {
      hello: "اهلا",
      billingPlan: "خطة الفواتير",
      role: "وظيفة",
      email: "البريد الإلكتروني",
      phone: "هاتف",
      editProfile: "تعديل الملف الشخصي",
    },
  },
  menu: {
    parametrageEtablissement: "إعدادات المؤسسة",
    parametrageComptabilite: "إعدادات المحاسبة",
    parametrageScolarite: "إعدادات المدرسة",
    smartSchedule: "الجدول الذكي",
    teacherAvailability: "توفر المعلم",
    calender: "التقويم و الأحداث",
    supervision: "المراقبة",
    ptchat: "محادثات الأولياء مع الأساتذة",
    cheques: "الشيكات",
    chat: "الدردشة",
    logout: "تسجيل خروج",
    etablissement: "المؤسسة",
    generale: "عامة",
    accueil: "الإستقبال",
    parametrage: "إعدادات",
    actualite: "الأخبار",
    administration: "الادارة",
    autorisation: "الصلاحيات",
    utilisateur: "المستخدم",
    liste: "قائمة الطلاب",
    activites: "الأنشطة",
    cantine: "المطعم المدرسي",
    etatPaiement: "حالة الدفع ",
    inscriActiv: "التسجيل: أنشطة",
    scolarite: "المدرسة",
    inscriScol: "التسجيل المدرسي",
    etatPaiements: "حالة الدفع ",

    listePunition: "قائمة العقوبات",
    emploi: "جدول الأوقات",
    eleves: "التلاميذ",
    enseignants: "المعلمون",
    bulletin: "بطاقة الأعداد",
    bulletinStatistique: "الإحصاء المدرسي",
    notes: "الأعداد",
    notesModule: "درجات المواد",
    notesParEleve: "درجات التلاميذ",
    notesModuleComplet: "درجات الوحدات",
    personnel: "طاقم العاملين",
    listePersonnels: "قائمة الموظفين",
    listeEnseignants: "قائمة المعلمين",
    listeActivites: "قائمة الأنشطة",
    virementBancaire: "حوالة مصرفية",
    listeEleves: "قائمة التلاميذ",
    comptabilite: "المحاسبة",
    etatRecettes: "قوائم الدخل",
    journaleCaisse: "مجلة النقدية",
    depenses: "المصاريف",
    depensesPersonnel: "حساب الأجور",

    etatImpayes: "حالة غير مدفوعة الأجر",
    listeReglement: "قائمة المدفوعات",
    reglementsAnnules: "المدفوعات الملغاة",
    etatImpayesActivites: "حالة الأنشطة غير مدفوعة الأجر ",
    etatImpayesScolarite: "حالة المدرسية الغير مدفوعة الأجر",
    declaration: "بيان",
    declarationTva: "بيان TVA",
    chiffreAffaires: "حجم الأعمال",
    chiffreAffairesScolarite: "معدل دوران الرسوم الدراسية",
    statistique: "الإحصائيات",
    administration: "الإدارة",
    actulite: "الأخبار",
    sms: "الرسائل القصيرة",
    envoyerSms: "إرسال رسائل قصيرة",
    envoyerSmsLogin: "تسجيل دخول الوالدين",

    envoyerParClasseSms: "إرسال إلى الفصول",
    historiqueSms: "السجل",
    caisseAvance: "الدفع المسبق",
    assiduite: "دفتر الغيابات",
    preinscription: "التسجيل المسبق",

    profile: "ملفي الشخصي",

    certificate: "شهادة",
  },

  sms: {
    paymentRappel: "تذكير بالدفع",
    deatilsAutoSmsTitle: "تفاصيل الرسائل الآلية",
    deatilsAutoPnTitle: "تفاصيل الإشعارات الآلية",
    deatilsAutoSms: "المزيد من التفاصيل",
    newPayment: "عملية دفع جديدة",
    newInscription: "تسجيل جديد",
    newAbsence: "غياب أو تأخر",
    newCourse: "درس أو تمرين",
    bulletinDispo: "بطاقة الأعداد متوفرة",
    actualite: "أخبار جديدة",
    autoSms: "إعدادات الرسائل التلقائية",
    autoPn: "إعدادات الإشعارات التلقائية",
    votreSolde: "رصيدكم",
    nbrSmsRestant: "عدد الرسائل القصيرة المتبقيّة",
    nomlExpediteur: "إسم المرسل",
    desclExpediteur: "يتم إرسال الرسائل القصيرة بإسم",
    gererListeDistinateurs: "إدارة قائمة المستلمين",
    envoyerDesSms: "إرسال الرسائل القصيرة",
    listeVosEleves: "قائمة التلاميذ",
    listeClasses: "قائمة الفصول",
    selectionnerEleves: "حدد التلاميذ",
    selectionnerClases: "حدد الفصول",
    pressEnterToSelect: "إضغط على إدخال للتحديد",
    entrerNumerosSpecifiques: "ادخل الأرقام المخصصة",
    entrerNumerosSpecifiquesDesc: "يجب الفصل بين أرقام الهواتف بفاصلات",
    categorieTag: "الفئة / العلامة",
    categorieTagDesc: "تساعدك العلامات على تنظيم رسائلك بشكل جيد.",
    saisirCategorieTag: "أدخل الفئات / العلامات",
    contenueVotreMessage: "أدخل محتوى رسالتك",
    contenueMessage: "أدخل محتوى الرسالة القصيرة",
    listeFinale: "القائمة النهائية",
    nombreSms: "عدد الرسائل القصيرة",
    nombreMessage: "عدد الرسائل",
    nomberTotal: "العدد الجملي",
    nombreMessageParCategorie: "عدد الرسائل بالنسبة للكل فئة",
    nombreCaracteres: "عدد الحروف",
    pere: "الأب",
    mere: "الأم",
    specifique: "مخصص",
    destinataire: "المتلقي",
    telephone: "الهاتف",
    parents: "الأولياء",
    personnel: "شخصي",
    sanction: "عقوبة",
    bulletin: "بطاقة أعداد",
    envoyer: "إرسال",
    nonInscrit: "غير مسجّل",
    noOptions: "القائمة فارغة",
    jours: "الأيام",
    boiteEnvoieSms: "صندوق الرسائل القصيرة المرسلة",
    message: "الرسالة",
    numsSpecifiques: "الأرقام المخصصة",
    listeParents: "قائمة الأولياء",
    envoyeParUser: "تم إرسالها عبر المستخدم",
    groupe: "مجموعة",
    tags: "الفئات / العلامات",
    envoiDate: "تاريخ الإرسال",
    selectAll: "اختر الكل",
    //send logins
    envoyerDesSmsLogins: "إرسال رسائل قصيرة لتسجيل الدخول",
    alertNumberSMS:
      "إذا كان اسم المستخدم وكلمة المرور طويلين (يتجاوزان 22 حرفًا) ، فيمكن أن يستهلك عدد الرسائل القصيرة للوالد 2 رسالة نصية قصيرة من الرصيد",
  },

  accueil: {
    activite: "نشاط",
    eleves: "الطلاب",
    classes: "فصل دراسي",
    serviceActiv: "خدمات / أنشطة",
    benefice: "اجمالي الربح",
    montant: "مقدار",
    pourcentage: "النسبة المئوية",
    depense: "إجمالي المصروفات",
    RevenuDep: "المصروفات / الدخل ",
    depenses: "نفقات",
    revenu: "الإيرادات",
    tnd: "دينار",
  },

  action: {
    reinitialiser: "إعادة تعيين",
    clone: "استنساخ",
    simpleBadge: "شارة بسيطة",
    badgeAvecPhoto: "شارة مع صورة",
    reset: "إعادة تعيين",
    imprimerTab: "طباعة الجدول",
    imprimerBadges: "طباعة الشارات",
    imprimerNewBadges: "طباعة الشارات مع الصور",
    actions: "أجراءات",
    supprimer: "حذف",
    ajouter: "إضافة",
    modifier: "تعديل",
    consulter: "مراجعة",
    annuler: "إلغاء",
    enregistrer: "حفظ",
    afficher: "عرض",
    voir: "مشاهدة",
    desactiver: "إلغاء نشاط",
    activer: "تفعيل",
    imprimer: "طباعة",
    confirmer: "تأكيد",
    changer: "تغيير",
    importer: "تنزيل",
    preview: "عرض",
    clone: "استنساخ",
  },
  actualite: {
    ajouterAlbum: "إضافة ألبوم",
    ajouterActualite: "إضافة خبر",
    date: " تاريخ الإصدار",

    tousClasses: "جميع الأقسام",
    titre: "العنوان",
    categorie: "الفئة",
    cibles: "هدف",
    jaimes: "أعجبني",
    photos: "الصور",
    dateCreation: "تاريخ الإنشاء",
    tous: "الكل",
    enseignants: "المعلمون",
    eleves: "الطلاب",
    teachers: "المعلمون",
    students: "الطلاب",
    administration: "الادارة",
    parents: "الآباء",
    cliquer: "انقر أو اسحب الملف إلى المنطقة لتحميله",
    support:
      "دعم التنزيل الفردي أو الجماعي. احظر بشدة تحميل بيانات الشركة أو ملفات المجموعة الأخرى",
    description: "وصف",
    ajouterPub: "أضف منشورًا",
    trimestre1: "الثلاثي الأول",
    trimestre2: "الثلاثي الثاني",
    trimestre3: "الثلاثي الثالث",
    trimester_one: "الثلاث الأصلي",
    trimester_two: "الثلاث المستنسخ",
    retour: "إرجاع",
    pasImages: "لا توجد صور",
    mentionJaimes: "الإعجابات",
    modifierImages: "تحرير الصور",
    specificFor: "مخصص إلى",
    levels: "المستويات",
    classrooms: "الأقسام",
    students: "التلاميذ",
    specified: "مخصص",
    specifiedInfo: "سيتم تطبيق التخصيصات على الوالدين فقط",
  },

  etablissement: {
    accessUpdated: "تم تحديث الوصول بنجاح",
    editingAccess: "تعديل الوصول",
    access: "الوصول",
    editAccess: "تعديل الوصول",
    config: "الإعدادات",
    editConfig: "تعديل الإعدادات",
    accessConfig: "تم تعديل الإعدادات بنجاح",
    editingConfig: "جاري تعديل الإعدادات",
    alertDisableChat:
      "تمكين أو تعطيل الدردشة بين الآباء والمعلمين (إذا تم تعطيلها، سيفقد المعلمون والآباء الوصول إلى الدردشة على الفور في تطبيقات الهاتف المحمول والويب)",
    etablissements: "المؤسسات",
    classe: "فصل دراسي",
    classes: "الفصول الدراسية",
    salle: "القاعة",
    salles: "القاعات",
    services: "الخدمات",
    activites: "الأنشطة",
    typeQualification: "أنواع الكفاءات",
    anneeScolaire: "سنة دراسية",
    logo: "الشعار",
    nom: "الإسم",
    designation: "التعيين",
    directrice: "(ة)المدير",
    dateEnregistrement: "تاريخ التسجيل",
    telephone: "الهاتف",
    adresse: "العنوان",
    type: "النوع",
    niveau: "مستوى",
    fraisInscri: "رسوم التسجيل",
    fraisMensuel: "رسوم شهرية",
    emploi: "جدول أوقات",
    dateCreation: "تاريخ الإنشاء",
    capacite: "طاقة الإستيعاب",
    fournisseurs: "المزودين",
    fournisseur: "المزود",
    fournisseurNonRequis: "المزود (غير مطلوب) :",
    service: "خدمات",
    responsable: "المسؤول",
    responsableActivite: "مسؤول النشاط",
    ordrePriorite: "الترتيب / الأولوية",

    order: "ترتيب",
    dateDebut: "تاريخ البدء",
    dateFin: "تاريخ النهاية",
    seuleFois: "مرة واحدة فقط",
    entrerInfo: "أدخل المعلومات في الحقول أدناه",
    modifierEmploi: "{name} تعديل الجدول الزمني لـ",
    other: "أخرى",
    editAlert:
      "تعديل العام الدراسي لا ينتج عنه تعديل في شهور تسجيل التلاميذ المسجلين بالفعل ، هل أنت متأكد من التعديل؟",
    clickOrDragToUpload: "انقر أو اسحب ملفًا إلى هذه المنطقة للرفع",
    clickForUploadLogo: "انقر لتعديل الشعار",
    ecole: "مدرسة الابتدائية",
    college: "مدرسة إعدادية",
    lycee: "مدرسة ثانوية",
    collegeEtLycee: "مدرسة إعدادية و ثانوية",
    primaireEtCollege: "مدرسة ابتدائية و إعدادية",
    jardinEnfant: "روضة أطفال",

    cache: "الختم",
    choisir: "إختيار",
    matriculeFiscale: "رقم التسجيل الضريبي",
    ArabicName: "الإسم بالعربية",
    delegeRegional: "المندوبية الجهوية",
  },

  comptabilite: {
    comptabilite: "محاسبة",
    fournisseur: "المزود",
    typeDepense: "نوع المصاريف",
    typeRecette: "نوع الدخل",
    nom: "اسم",
    adresse: "عنوان بريدي",
    telephone: "هاتف",
    email: "البريد الإلكتروني",
    codeTVA: "رمز TVA",
  },
  scolarite: {
    affichageParametrage: "إعدادات العرض",
    matieres: "المواد",
    modules: "الوحدات",
    modulesSpec: "الوحدات المخصصة",
    nomModule: "إسم الوحدة",
    affectationDansClasses: "تعيين في الأقسام",
    affectationEnseignants: "تعيين المعلمين",
    enseignants: "معلمون",
    typePunitions: "نوع العقوبات",
    nom: "اسم",
    coefficient: "الدرجة",
    listeMatieres: "قائمة المواد",
    listeModules: "قائمة الوحدات",
    infoModule:
      "قم بتعيين الموضوعات للوحدات النمطية من أجل تنظيم إدارة الملاحظات!",
    listeModulesSpec: "قائمة الوحدات المخصصة",
    infoModuleSpec:
      "قم بتعيين الموضوعات للوحدات المخصصة من أجل تنظيم إدارة الملاحظات!",
    ajouterModule: " إضافة وحدة",
    ajouterMatiere: "إضافة مادة",
    affectes: "متأثر",
    nonAffectes: "غير متأثر",
    recherche: "البحث",
    element: "عنصر",
    aucunDonnee: "لا توجد بيانات",
    modifierListeMatieres: "{name} : تعديل قائمة مواد الوحدة",
    modifierListeEnseignants: "{name} : تعديل قائمة معلمين الفصل",
    supprime: "محذوف",
    charcherParClasse: "البحث عبر إسم الفصل",
    confirmDeleteModule:
      "سيتم حذف جميع الحصص المتعلقة بهذه الوحدة. تستخدم هذه الوحدة في الفصول الدراسية أدناه",
    certification: "الشهادات",
    appreciation: "ملاحظات",
    modulesVerySpec: "الوحدات المخصصة للغاية",
    subsubjects: "المواد الفرعيّة",
    subsubject: "مادة فرعيّة",
    ajouterSubsubject: "إضافة مادة فرعيّة",
    addNewSubsubject: "إدارة المواد الفرعيّة",
    choisirSubsubject: "إختر مادة فرعيّة",
    mark: "العدد الأقصى",
    listeModulesVerySpec: "قائمة الوحدات المخصصة للغاية",
    infoModuleVerySpec:
      "قم بتعيين الموضوعات للوحدات المخصصة للغاية من أجل تنظيم إدارة الملاحظات!",
    totalMarksMustBe20:
      "{subject} : المجموع الكلي للمواد الفرعيّة يجب أن يكون 20",

    subjectDejaExiste: "المادة موجودة بالفعل",
    cloneParam: "استنساخ إعدادات",
    selectParamToClone: "اختر إعدادات لاستنساخها",
    duppModules: "استنساخ الوحدات من فصل دراسي واحد إلى الآخر",
  },
  autorisation: {
    accueil: "ترحيب",
    admin: "المسؤول",
    designation: "تعيين",
    compteAdmin: "حساب المسؤول",
    dossierMedical: "ملف طبي",
    listeEleves: "قائمة الطلاب",
    parametrage: "ضبط",
    activite: "نشاط",
    administration: "الادارة",
    scolarite: "التعليم",
    personnel: "عامل",
    personnels: "طاقم العاملين",
    comptabilite: "محاسبة",
    declarations: "الإعلانات",
    paiements: "المدفوعات ",
    medicalNote: "مذكرة طبية",
    medicalNoteContext: "يمكن للوالدين عرض وتعديل الملاحظات الطبية لأطفالهم.",
  },

  utilisateur: {
    listeUtilisateur: "قائمة المستخدمين",
    nom: "الإسم",
    role: "الوظيفة",
    acces: "الوصول إلى المؤسسات",
    actif: "نشيط",
    nonActif: "غير نشيط",
    mdp: "كلمة السر",
    activerDesactiver: "مفعل / غير مفعل",
    confirmerMdp: "تأكيد كلمة المرور",
    changerMdp: "يمكنك تغيير كلمة المرور",
    active: "نشط",
    nonActive: "غير نشط",
    desactiverAlert: "هل تريد إلغاء نشاط هذا المستخدم؟",
    activerAlert: "هل تريد تنشيط هذا المستخدم؟",
    userDesactive: "تم إلغاء نشاط المستخدم",
    userActive: "تم تنشيط المستخدم",
  },
  liste: {
    listeElevesArchive: "قائمة التلاميذ المؤرشفين",
    reactive: "إعادة تفعيل",
    reasonArchive: "سبب الأرشفة",
    addFiles: "إضافة ملفات",
    studentPhoto: " صورة التمليذ",
    studentFiles: "ملفات التلميذ",
    consulterProfil: "مراجعة الملف الشخصي لمزيد من التفاصيل",
    nomPrenom: "الإسم واللقب",
    scolarite: "التعليم",
    ficheParent: "بيانات الوالدين",
    attestation: "شهادة حضور",
    inscription: "التسجيل",
    supprimerAnnuler: " حذف / إلغاء",
    inscriSupprimer: "تسجيل / حذف",
    filles: "بنات",
    garcons: " اولاد",
    total: "المجموع",
    nonInscrits: "غير مسجل",
    inscrits: " مسجل",
    parents: "الآباء",
    ajouterEleve: " أضف تلميذًّا",
    ajouterDossier: "أضف مجلد",
    ajouterPersonnel: "أضف الموظفين",
    ajouterEnseignant: "أضف مدرسًا",
    listeEleves: "قائمة الطلاب",
    administration: "الادارة",
    nom: "اللقب",
    prenom: "الاسم الاول",
    notes: "درجات",
    dateNaissance: "تاريخ الميلاد",
    sexe: "الجنس",
    adresse: "عنوان بريدي",
    telephone: "هاتف",
    classes: "الأقسام",
    ajouterParent: "إضافة والد جديد",
    parentsExis: "الآباء الحاليون",
    ficheParents: "ورقة الوالدين",
    nomMere: " لقب الأم",
    prenomMere: "اسم الأم",
    telMere: "هاتف الأم",
    nomPere: " لقب الاب",
    prenomPere: "اسم الاب",
    telPere: "هاتف الاب",
    metierPere: "وظيفة الأب",
    metierMere: "وظيفة الأم",
    adresseParents: "عنوان بريدي ",
    emailParents: "البريد الإلكتروني للوالدين",
    reduction: "تخفيض رسوم التسجيل",
    reductionMensuel: "خصم على الرسوم الشهرية",
    identifiantUnique: "المعرف الوحيد",
    regionalEducation: "المندوبية الجهوية للتربيّة",
    recuEn: "سلمت في",
    nonInscrit: "غير مسجل",
    changementNonEffectue: "التغيير لم يتم",
    champsVides: "لا يمكن أن تكون الحقول فارغة",
    imprimerLoginsEnsg: "تحميل دخول المعلمين"
  },
  cantine: {
    modifierMenu: "عدل القائمة ",
    cantine: "المطعم المدرسي",
    dateChoisie: "التاريخ المختار",
    choisirDate: "اختر تاريخًا لإدراج القائمة ",
    ajouterRepas: "أضف وجبة",
    ajouterMenu: " أضف قائمة جديدة لـ",
    choisirJour: "اختر الأيام",
    nom: "اللقب",
    type: "نوع ",
    repeter: "كرر",
    saisirNom: " أدخل اسم الوجبة",
    saisirType: "اختر نوع الوجبة",
    plat: " الطبق الرئيسي",
    boissons: " مشروبات",
    autre: "الآخرين",
    dessert: "الحلويات",
    menuIntrouvable: "القائمة غير موجودة ، الرجاء إضافة وجبات!",
    ajouterNewMenu: "{date} إضافة وجبة جديدة لـ",
    modifierMenu: "{name} تعديل وجبة ",
  },
  paiement: {
    avoir: "استرداد",
    note: "ملاحظة",
    paiementClasse: "دفع/فصل دراسي",
    nom: "اسم التلميذ",
    classe: "فصل دراسي",
    parents: "الآباء والأمهات",
    tels: "هاتف الوالدين",
    etat: "حالة الدفع",
    fraisInscri: "رسوم التسجيل",
    fraisMensuel: "رسوم شهرية",
    dateDebut: "تاريخ البدء ",
    dateFin: "تاريخ النهاية",
    tousMois: "كل الشهور",
    mois: "شهر",
    tousClasses: "جميع الفصول الدراسية",
    order: "ترتيب",
    service: "الخدمات",
    nbEleves: "عدد الطلاب",
    listeClasses: "قائمة الفصول الدراسية",
    listeEleves: "قائمة الطلاب",
    imprimerTab: "طباعة الجدول",
    selectionnerMois: "إختر الشهر",
    paye: "مدفوع",
    inscriPaye: "التسجيل مدفوع",
    inscriNonPaye: "التسجيل غير مدفوع",
    impaye: "غير مدفوع",
    selectionnerDate: "حدد التاريخ",
    reduction: "خصم على التسجيل",
    reductionMensuel: "الخصم شهري",
    activite: "نشاط",
    annulation: "الإلغاء",
    listeActivites: "قائمة الأنشطة",
    dt: " دينار",
    non: "لا",
    chercher: "بحث",
    annulerInscri: "هل تريد إلغاء الاشتراك؟",
    eleveRequis: "مطلوب تلميذ واحد على الأقل",
    activiteRequis: "النشاط مطلوب",
    dateDebutRequis: "تاريخ البدء مطلوب",
    dateFinRequis: "تاريخ الانتهاء مطلوب",
    msgReduction: "اضبط التخفيض على صفر إذا كنت لا تريد إضافة التخفيض ",
    typeDepense: "نوع المصاريف مطلوب",
    eleve: "التلميذ مطلوب",
  },
  inscription: {
    nom: "الاسم الكامل",
    dateNaissance: "تاريخ الميلاد",
    sexe: "الجنس",
    tels: "هاتف الوالدين",
    parent: "الولي",
    nouvInscri: "تسجيل جديد",
    nbEleves: "{number} : عدد الطلاب",
    ficheParentClasse: "ملف الفصل / الوالدين",
    ficheClasse: " ملف الفصل",
    inscription: "تسجيل",
    enfant: "الطفل",
    fraisInscri: "رسوم التسجيل",
    fraisMensuel: "رسوم شهرية",
    remise: "الخصم ",
    remiseInscri: "خصم على التسجيل",
    remiseMensuel: "خصم شهري",
    rechercher: "البحث",
    mensuel: "شهريا",
    chercherParActivite: "البحث عن طريق اسم النشاط",
    sureToInscri: "هل أنت متأكد من رغبك في تسجيل هذا التلميذ؟",
  },
  punition: {
    eleve: "التلميذ",
    nom: "اللقب",
    prenom: "الإسم",
    details: "التفاصيل",
    punition: "العقاب",
    temps: "زمن",
    raison: "السبب",
    description: "الوصف",
    validation: "فاعلية",
    valides: "تم التأكيد",
    rejetes: "مرفوض",
    nonEvalues: "لم تتم مراجعته",
    dateAjout: "تاريخ الإضافة",
    dateEvalution: "تاريخ المراجعة",
    valider: "تأكيد",
    rejeter: "رفض",
    rejectReason: "سبب رفض العقوبة",
    reasonRequired: "سبب الرفض مطلوب",
  },

  days: {
    sunday: "الأحد",
    monday: "الأثنين",
    tuesday: "الثلثاء",
    wednesday: "الأربعاء",
    thursday: "الخميس",
    friday: "الجمعة",
    saturday: "السبت",
  },

  emploi: {
    validated: "الآن أصبحت الجداول الدراسية متاحة للفصول التالية: {name}",
    unvalidated: "الجداول الزمنية غير متاحة الآن للفصل الدراسي: {name}",
    hideEmploin: "لن يكون جدول هذه الفئة متاحًا للآباء بعد الآن",
    validateEmploin: "سيكون جدول هذه الفئة متاحًا للآباء",
    emplois: "جداول",
    temps: "الأوقات",
    trimestre: "الفصل الدراسي",
    listeClasses: "قائمة الفصول الدراسية",
    listeEnseignants: "قائمة المعلمين",
    emploiEnseignants: " جداول المعلمين ",
    imprimerEmploi: "  طباعة الجدول الزمني ",
    imprimerEmploiAdministrative: "طباعة الجدول الإداري",
    enseignant: "المعلم",
    matiere: "المواد",
    salle: "القاعة",
    duree: "المدة الزمنية",
    classe: "فصل دراسي",
    choisirEnseignant: "إختر مدرسًا ",
    choisirMatiere: "إختر مادة",
    choisirSalle: "إختر القاعة",
    choisirDuree: "إختر المدة",
    choisirClasse: "إختر فصل دراسي",
    ajouterSeance: "إضافة حصة ",
    modifierSeance: " تعديل حصة",
    disponible: "متوفرة",
    indisponible: "غير متوفره",
    modeModif: "تم تنشيط وضع التحرير!",
    desc: "يمكنك تغيير مكان و مدة الحصص",
    totaleHeures: "مجموع الساعات",
    moyenneHeuresJour: "معدل الساعات في اليوم",
    maximunHeuresJour: "الحد الأقصى للساعات في اليوم",
    totaleClasses: "مجموع الفصول",
    totaleTeachers: "مجموع الأساتذة",
    seance: "الحصة",
    seanceListe: "قائمة الحصص",
    choisirName: "اكتب اسم الحصة",
    name: "اسم الحصة",
    includedSubjects: "يمكن للاستاذ تدريس",
  },
  bulletin: {
    manageCertType: "تعديل الشهائد ل :",

    defaultModule: "تعيين وحدات افتراضية",
    defaultModuleConfirm:
      "هذا الإجراء سيحذف الوحدات الحالية. هل ترغب في المتابعة بتعيين الوحدات الافتراضية؟",
    defaultDone: "تم تحديث الوحدات الافتراضية بنجاح",

    coefTrimester1: "ضارب الثلاثي الأول",
    coefTrimester2: "ضارب الثلاثي الثاني",
    coefTrimester3: "ضارب الثلاثي الثالث",
    moyenneGeneral: "المعدل العام",
    bulletinCoefs: "ضوارب بطاقات الأعداد",
    coefBulletinsSaved: "تم حفظ ضوارب بطاقات الأعداد بنجاح",

    decisionConseil: "قرار مجلس القسم",
    finalBulletin: "بطاقة نهايّة السنة",
    syncClass: "مزامنة الفصل",
    syncSubject: "مزامة المادة",
    syncMarks: "مزامنة الأعداد",
    sureToSync: "هل تريد تعديل الأعداد الموجودة مسبقًا",
    syncDone: "تم مزامنة الأعداد بنجاح",
    date: "تاريخ الطبع",
    parametrageSaved: "تم حفظ إعدادات نتائج الدراسية بنجاح",
    label: "الصياغة",
    moyennePersonalized: "المعدل المخصص للمدرسة",
    moyenneSpecifique: "المعدّل المخصص",
    moyennePedagogique: "المعدّل النموذجي",
    moyenneVerySpecifique: "المعدّل المخصص للغاية",
    formulePersonalized: " الصيغة",
    imprimerFichModuleVide: "نموذج فارغ",
    imprimerFichModule: "نموذج مكتمل",
    certByMoyenne: "الشهادات حسب معدلات التلاميذ",
    imprimerBulletin: "اطبع تقرير المدرسة",
    note: "درجة",
    nom: "اسم التلميذ",
    bulletin: "تقرير المدرسة",
    notes: "درجات",
    matieres: "المواد",
    bulletinPedagogique: "بطاقات اعداد بيداغوجية",
    bulletinSpecifique: "بطاقات اعداد نموذجي",
    bulletinVerySpecifique: "بطاقات اعداد مخصصة",

    performancesMatiere: "أداء المواد",
    plus15: "أعلى من 15",
    plus12: "أعلى من 12",
    plus9: "أعلى 9",
    mois9: "أقل من 9",

    listeClasses: "قائمة الفصول",
    classesActives: "الفصول النشطة",
    nombreClassesNonNote: "عدد الفصول ذات درجات ناقصة",
    tousClassesSontNotes: "!كل الفصول ذات درجات مكتملة",
    nombreMatieresNonNote: "عدد المواد ذات درجات ناقصة",
    tousMatieresSontNotes: "!كل المواد ذات درجات مكتملة",
    nombreElevesNonNotes: "عدد التلاميذ الغير الحاصلين على درجات",
    tousElevesSontNotes: "!كل التلاميذ لديهم درجات كاملة",

    classes: "فصل",
    toutMatieres: "كل المواد",
    enregistrerTout: "حفظ الكل",

    typeIntrouvable: "النوع غير موجود",
    saisirSchoolType: "الرجاء إدخال نوع مؤسستك في الإعدادات - منشأة!",

    confirmPrintMatieres:
      "يجب إكمال علامات المواد أدناه ({count}) لإنشاء بطاقة الاعداد!",
    confirmPrintEleves:
      "يجب ملء درجات التلاميذ أدناه ({count})) لإنشاء بطاقة الاعداد!",
    generateAnyway: "إنشاء على كل حال",

    listeClassesNonNote: "قائمة الفصول ذات درجات ناقصة",
    listeMatiereNonNote: "قائمة المواد ذات درجات ناقصة",
    listeEleveNonNote: "قائمة التلاميذ غير الحاصلين على درجات",

    performancesNotes: "أداء الدرجات",
    notePlusHaute: "أعلى درجة",
    noteEleve: "درجة التلميذ",
    notePlusBasse: "أدنى درجة",

    moyennesModules: "معدل الوحدات",

    maximaleMoyenne: "أعلى معدل",
    moyenne: "المعدل",
    minimaleMoyenne: "أدنى معدل",
    imprimerTout: "طباعة الكل",

    moyenneTrimestre: "معدل الثلاثي : {moyenne}",
    moyenneMax: "أعلى معدل : {moyenne}",
    moyenneMin: "أدنى معدل : {moyenne}",

    validated: "بطاقة الأعداد أصبحت متاحة للفصل {name} الأن",
    unvalidated: "بطاقة الأعداد أصبحت غير متاحة للفصل {name} الأن",
    hideBulletin: "بطاقات أعداد لهذا القسم ستصبح غير متاحة للأولياء",
    validateBulletin: "بطاقات أعداد لهذا القسم ستصبح متاحة للأولياء",
    cancelValidation: "إلغاء الإتاحة للأولياء",
    validatedClassroom: "إتاحة للأولياء",

    deleteMark: "هل تريد حذف هذه العلامة؟",
    markDeletedSucc: "تم حذف العلامة بنجاح",
    markDeletedFail: "لا يمكن حذف العلامة ، يرجى المحاولة مرة أخرى!",
  },

  personnel: {
    nom: "اللقب",
    prenom: "الإسم",
    adresse: "العنوان",
    telephone: "الهاتف",
    qualification: "الكفاءة",
    dateNaissance: "تاريخ الميلاد",
    typeContrat: "نوع العقد",
    salaire: "الراتب",
    cin: "البطاقة الشخصية",
    etatCivil: "حالة المدنية",
    niveau: "المستوى",
    experiences: "خبرة",
    dateEntretien: "تاريخ المقابلة",
    qualifications: "كفاءات",
    tauxHoraire: "معدل ساعة",
    detailPaiement: "بيانات الدفع",
    jourSemaine: "أيام في الأسبوع",
    cnss: "الصندوق الوطني للضمان الاجتماعي",
    rib: "بيان التعريف البنكي",
    autre: "اخر",
    notes: "درجات",
    infoBase: "معلومات اساسية",
    infoContrat: "معلومات العقد",
    chercher: "بحث",
    listeQualifications: "قائمة الكفاءات",
    staff: "الموظفين",
  },

  enseignant: {
    nom: "اللقب",
    prenom: "الاسم",
    adresse: "عنوان بريدي",
    telephone: "هاتف",
    qualification: "الكفاءة",
    dateNaissance: "تاريخ الميلاد",
    typeContrat: "نوع العقد",
    salaire: "الراتب",
    cin: "البطاقة الشخصية",
    etatCivil: "حالة المدنية",
    niveau: "المستوى",
    experiences: "خبرة",
    dateEntretien: "تاريخ المقابلة",
    qualifications: "كفاءات",
    tauxHoraire: "معدل ساعة",
    detailPaiement: "بيانات الدفع",
    jourSemaine: "أيام في الأسبوع",
    cnss: "الصندوق الوطني للضمان الاجتماعي",
    rib: "بيان التعريف البنكي",
    autre: "اخر",
    notes: "درجات",
    infoBase: "معلومات اساسية",
    infoContrat: "معلومات العقد",
    chercher: "بحث",
    listeQualifications: "قائمة الكفاءات",
    matiere: "المادة",
    specialite: "تخصص",
    grade: "رتبة",
    email: "البريد الإلكتروني",
    mdp: "كلمة المرور",
    compte: "الحساب",
    detailPedagogiques: "المعلومات البيداغوجية",
    usernameEmail: "إسم المستخدم / البريد الإلكتروني",
    photo: "الصورة",
    conseillersPedagogiques: "المرشدون التربويون",
    ajouterConseillerPedagogique: "إضافة مرشد تربوي",
    enterInfoConseillerPedagogique:
      "أدخل معلومات المرشد التربوي في الحقول أدناه",
    niveauxSuperviser: "المستويات الخاضعة للإشراف",
    niveauxRequis: "المستويات مطلوبة",
    listeNiveaux: "قائمة المستويات",
    aucunConseillerPedagogique: "لم يتم العثور على مستشار تربوي",
    needValidation: "التحقق التلقائي من التمارين و الدروس",
    needValidation2: " الدورات ",
  },

  etatPaiement: {
    personnel: "عامل",
    salaire: "الراتب",
    avance: "تسبقة",
    nbJours: "عدد الأيام",
    mois: "شهر",
    etatPaiement: "حالة الدفع",
    virementBancaire: "حوالة مصرفية?",
    paiementAction: "دفع",
    selectionnerشهر: "إختر شهرا",
  },
  virement: {
    rib: "بيان التعريف البنكي",
    nom: "اللقب",
    prenom: "الاسم",
    etatPaiement: "حالة الدفع",
    salaire: "الراتب",
    mois: "الشهر",
    nbJours: "عدد الأيام",
    chercher: "بحث",
  },

  recette: {
    fournisseurProfil: "ملف المزود",
    checksDueThisWeek: "الشيكات المستحقة هذا الأسبوع",
    dateAjout: "تاريخ الإضافة",
    doneBy: "تم بواسطة",
    cancelledBy: "ألغى بواسطة",
    montantCheque: "المبلغ عبر الصك البنكي",
    montantEspece: "المبلغ النقدي",
    montantTotal: "المبلغ الإجمالي",
    typePaiement: "طريقة الدفع",
    montant: "مقدار",
    nom: "اسم الزبون",
    typeRecete: "نوع الدخل",
    typeReglement: "نوع الأجر",
    modePaiement: "طريقة الدفع",
    montantHT: "المبلغ غير شامل الضريبة",
    montantTTC: "المبلغ شاملاً الضريبة",
    type: "النوع",
    datePaiement: "تاريخ الدفع",
    numCheque: "رقم الشيك",
    imprimer: "طباعة ",
    csv: "CSV",
    tous: "الكل",
    cheque: "صك بنكي",
    espece: "نقد",
    chequeEtEspece: "صك و نقد",
    detailsCheque: "تفاصيل الصك",
    numero: "الرقم",
    titulaire: "صاحب الحساب",
    banque: "المصرف",
    dateEcheance: "تاريخ الاستحقاق",
    fermer: "إغلاق",
    ok: "حسنا",
    typeJournal: "نوع السجل",
    selectionnerDate: "حدد تاريخ",
    motCles: "الكلمات الدالة",
    chercher: "بحث",
    bouillard: "Bouillard صندوق ",
    annule: "ملغى",
    personnel: "عامل",
    salaire: "راتب",
    avance: "تسبقة",
    nbJours: "عدد الأيام",
    mois: "شهر",
    etatPaiement: "حالة الدفع",
    virementBancaire: "حوالة مصرفية",
    typeDepense: "نوع التكاليف",
    date: "تاريخ",
    fournisseur: "المزود",
    numSerie: "الرقم التسلسلي",
    nomParent: "اسم الوالدين",
    dateAnnulation: "تاريخ الإلغاء",
    facture: "فاتورة ؟",
    impression: "طباعة",
    annulation: "الإلغاء",
    paiementQuoi: "الدفع على ماذا",
    selectionnerParent: "حدد الولي",
    selectionnerEnfant: "حدد الطفل",
    titre: "عنوان",
    valider: "تاكيد",
    recuPaiement: "إيصال الدفع",
    facturePaiement: "فاتورة الدفع",
    paiementTotal: "المبلغ الإجمالي؟",
    paiementPour: "الدفع مقابل ",
    restePayer: "الدفوعات المتبقيّة",
    payer: "دفع",
    payerMain: "ادفع الآن ؟",
    reducRepet: "التخفيض المتكرر",
    reducFact: "تخفيض على هذه الفاتورة",
    reducInscri: "خصم على التسجيل",
    reductionMensuel: "خصم شهري",
    fraisInscri: "رسوم التسجيل",
    fraisMensuel: "رسوم شهرية",
    ajouterPaiement: "إضافة الدفع",
    montantRestant: "الكمية المتبقية",
    inscription: "تسجيل",
    telephone: "هاتف",
    frais: "التكاليف",
    enfant: "طفل",
    activite: "نشاط",
    tousMois: "كل الشهور",
    montantHTVA: "المبلغ غير شامل ضريبة القيمة المضافة",
    nonSeule: "لا (مرة واحدة فقط)",
    payeQues: "مدفوع ؟",
    recus: "الإيصالات",
    factureTag: "فاتورة",
    avantReduction: " قبل التخفيض",
    apresReduction: " بعد التخفيض",
    reduction: " التخفيض",
    saisirInfoCheque: "أدخل معلومات الشيك",
    saisirInfoChequeEtEspace: "أدخل معلومات الشيك والنقد",
    timbreFiscal: "الطابع الضريبي",
  },

  choisir: {
    enseignant: "اختر مدرسًا",
    matiere: "اختر مادة",
    salle: "اختر قاعة",
    duree: "اختر المدة",
    classe: "اختر فصل",
    repas: "اختر نوع الوجبة",
    jour: "اختر أيام هذه الوجبة",
    dateDebut: "اختر تاريخ البدء",
    dateFin: "اختر تاريخ الانتهاء",

    niveauClass: "اختر المستوى دراسي الفصل",
    template: "القالب",
  },

  requis: {
    template: "القالب مطلوب",
    smsContenue: "محتوى الرسالة القصيرة مطلوب",
    message: "الرسالة مطلوبة",
    coefficient: "الضارب مطلوب ",
    red: "اضبط التخفيض على صفر إذا كنت لا تريد إضافة التخفيض",
    activite: "النشاط مطلوب",
    cnss: "مطلوب CNSS",
    rib: "مطلوب RIB",
    specialite: "التخصص مطلوب",
    niveau: "المستوى مطلوب",
    etatCivil: "الحالة المدنية مطلوبة",
    jourSemaine: "مطلوب أيام في الأسبوع",
    tauxHoraire: "مطلوب معدل بالساعة",
    dateEntretien: "تاريخ الخدمة مطلوب",
    matiere: "المواد مطلوبة",
    typeContrat: "نوع العقد مطلوب",
    qualification: "الكفاءة مطلوبة",
    telephone: "يجب أن يتكون رقم الهاتف من 8 أرقام",
    cin8: "يجب أن تتكون رقم بطاقة الهوية من 8 أرقام",
    cin: "رقم بطاقة الهوية الوطنية مطلوب",
    pourcentage: "نسبة الخصم مطلوبة",
    order: "الطلب مطلوب",
    mail: "أدخل بريدك الإلكتروني!",
    mois: "الشهر مطلوب",
    mdp: "كلمة المرور مطلوبة",
    noWhitespace: "لا يمكن أن يحتوي الحقل على مسافة بيضاء",
    confirmation: "مطلوب تأكيد كلمة المرور",
    username: " اسم المستخدم مطلوب",
    usernameEmail: " اسم المستخدم أو البريد الإلكتروني مطلوب",
    titre: "العنوان مطلوب ",
    categorie: "اختيار القسم مطلوبe",
    designation: "التعيين مطلوب",
    cible: "الهدف مطلوب",
    salaire: "الراتب مطلوب",
    role: "الدور مطلوب",
    repas: "أدخل اسم الوجبة",
    depense: "نوع المصاريف مطلوب",
    fournisseur: "المزود مطلوب",
    nom: " مطلوب اسم ",
    min: " الحد الأدنى للعلامة المطلوبة ",
    max: " الحد الأقصى للعلامة المطلوبة",
    nomPersonnel: "إسم العامل مطلوب",
    date: "التاريخ مطلوب",
    selection: "مطلوب اختيار الوالدين",
    nomPere: "مطلوب اسم الاب",
    nomMere: "اسم الأم مطلوب",
    prenom: "الاسم الأخير مطلوب",
    prenomPere: "الاسم الأول للأب مطلوب",
    prenomMere: "الاسم الأول للأم مطلوب",
    telPere: "هاتف الأب مطلوب",
    telMere: "مطلوب هاتف الأم",
    email: "بريد إلكتروني خاطئ  ",
    activite: "النشاط مطلوب ",
    carac: "4 أحرف كحد أدنى لكلمة المرور ",
    genre: "الجنس مطلوب",
    adresse: "العنوان مطلوب",
    service: "يجب اختيار الخدمة",
    fraisInscri: "مطلوب رسوم التسجيل ",
    fraisMensuel: "الرسوم الشهرية مطلوبة",
    ajoutLogo: "أضف شعارًا جديدًا",
    coefficient: "الضارب مطلوب",
    dateNaissance: "تاريخ الميلاد مطلوب",
    nbJours: "عدد الأيام مطلوب",
    montant: "المبلغ مطلوب ويجب أن يكون رقمًا",
    avance: " مقدما مطلوب",
    selectionEnfant: "مطلوب اختيار الطفل",
    etatPaiement: "حالة الدفع مطلوبة",
    specification: "المواصفات مطلوبة",
    observation: "المراقبة مطلوبة",
    reductionInscro: "مطلوب تخفيض رسوم التسجيل (ضع صفرًا إذا لم يكن هناك تخفيض",
    reductionMensuel:
      "مطلوب تخفيض الرسوم الشهرية (أدخل صفرًا إذا لم يكن هناك تخفيض)",
    supp: "هل انت متأكد من الحذف؟",
    supp2:
      "إذا قمت بحذف تلميذ ، فسيتم تحديث حالة الدفع الخاصة به ، وسيتم حذف جميع الأشهر غير المدفوعة. آمن للحذف؟ ",
    annu: "إذا قمت بإلغاء التسجيل ، فسيتم تحديث حالة تسجيل للعام الدراسي الحالي. هل أنت متأكد من إلغاء الاشتراك؟",

    appreciation: "الملاحظة مطلوبة",
  },

  success: {
    appreciationOptionAjout: "تم إضافة ملاحظة جديدة",
    appreciationOptionMSA: "تم تحديث الملاحظة",
    appreciationOptionSupp: "تم حذف الملاحظة",
    certificationAjout: "تمت إضافة الشهادة",
    certificationMSA: "تم تحديث الشهادة",
    certificationSupp: "تمت إزالة الشهادة",

    decisionConseilAjout: "تم إضافة القرار",
    decisionConseilMSA: "تم تحديث القرار",
    decisionConseilSupp: "تم حذف القرار",

    noteEnregistre: "تم حفظ العدد بنجاح",
    noteToutesEnregistre: "تم حفظ جميع الأعداد بنجاح",
    noteRemoved: "تمت إزالة العدد بنجاح",

    smsEnvoye: "تم إرسال الرسائل القصيرة بنجاح",

    modificationsEnseignantsEffectue: "تم تحديث المعلمين بنجاح",
    modificationsMatieresEffectue: "تم تحديث المواد بنجاح",

    cacheAjout: "تم رفع الختم بنجاح",
    cacheSupp: "تم حذف الختم بنجاح",
    inscriAjout: "تم إضافة التسجيل",
    inscriSupp: "تم حذف التسجيل",
    inscriAnnule: "تم إلغاء التسجيل",

    pubSupp: "تمت اضافة المنشور",
    pubSupp: "تم أرشفة المنشور",

    photoAjout: "تمت إضافة الصورة",
    compte: "تمت إضافة نوع الحساب",

    autorisationMSA: "تم تحديث التفويض",
    autorisationSupp: "تمت إزالة الصلاحية ",

    utilisateurAjout: " تمت إضافة المستخدم",
    utilisateurMSA: "تم تغيير المستخدم",
    utilisateurSupp: " تمت أرشفة المستخدم",
    utilisateurDesac: "تم إلغاء تنشيط المستخدم ",
    utilisateurActiv: " تم تنشيط المستخدم",

    repasAjout: "تمت إضافة الوجبة",
    repasMSA: "تم تغيير الوجبة",
    repasSupp: "تم حذف الوجبة",

    depenseMSA: "تم تحديث المصاريف",
    depenseSupp: "تم أرشفة المصاريف",

    reglementSupp: "تم حذف الدفع ",
    paiementAjout: "تم إضافة الدفع",
    dossierMSA: "تم تحديث الملف",
    dossierSupp: "تم حذف الملف",

    buildingMSA: "بنجاح {name} تم تحديث المؤسسة",

    eleveAjout: "تمت إضافة التلميذ",
    eleveMSA: "تم تحديث التلميذ",

    bulletinMSA: " تم تعديل النشرة ",

    typeDepenseAjout: "تمت إضافة نوع المصاريف",
    typeDepenseModif: "تم تغيير نوع المصاريف",
    typeDepenseArchiv: "تمت أرشفة نوع المصاريف",

    fournisseurAjout: "تمت إضافة المزود",
    fournisseurMSA: "تم تعديل المزود",
    fournisseurArchiv: "تمت أرشفة المزود",

    typeRecetteMSA: "تم تغيير نوع الدخل",
    typeRecetteAjout: "تم إضافة نوع الإيرادات",
    typeRecetteArchiv: "تمت أرشفة نوع الدخل",

    activiteAjout: "تمت إضافة النشاط",
    activiteSupp: "تم أرشفة النشاط",
    activiteMSA: "تم تعديل النشاط",

    emploiMSA: "تم تعديل جدول الأوقات",

    logoMSA: " تم تغيير الشعار ",
    fileUploadedPleaseSave: "تم رفع ملف {name} بنجاح ، يرجى الحفظ!",

    seanceAjout: " تمت إضافة الحصة",
    seanceMSA: "تم تعديل الحصة",
    seanceSupp: " تم أرشفة الحصة",

    punitionValid: "تم مراجعة العقوبة بنجاح",

    personnelAjout: " تمت إضافة الموظف",
    personnelMSA: "تغير الموظف",
    personnelSupp: "تمت أرشفة الموظف ",
    personnelNonSupp: "لم يتم إزالة الموظف",

    enseignantAjout: "تمت إضافة المعلم",
    enseignantMSA: "تم تعديل المعلم",
    enseignantSupp: "تم حذف المعلم",
    enseignantNonSupp: "لم يتم حذف المعلم",

    classeAjout: "تمت إضافة الفصل",
    classeMSA: "تم تعديل الفصل",
    classeSupp: "تم وضع الفصل في الأرشيف",

    moduleSupp: "تم إزالة الوحدة بنجاح",

    typePunitionAjout: "تمت إضافة نوع العقوبة",
    typePunitionMSA: "تم تغيير نوع العقوبة",
    typePunitionArchiv: "تمت أرشفة نوع العقوبة",

    typeQualificationAjout: "تم إضافة نوع الكفاءة",
    typeQualificationMSA: "تم تعديل نوع الكفاءة",
    typeQualificationArchiv: "تمت أرشفة نوع الكفاءة",

    matiereAjout: "تمت إضافة المادة",
    matiereMSA: "تم تعديل المادة",
    matiereSupp: "تمت أرشفة المادة",

    anneeAjout: "تمت إضافة العام الدراسي",
    anneeMSA: "تم تعديل العام الدراسي",
    anneeSupp: "تم أرشفة العام الدراسي",

    serviceAjout: "تمت إضافة الخدمة ",
    serviceMSA: "تم تعديل الخدمة",
    serviceSupp: "تمت إزالة الخدمة ",

    salleAjout: "تمت إضافة القاعة",
    salleMSA: "تم تعديل القاعة",
    salleSupp: "تم وضع القاعة في الأرشيف",

    eleveAjout: "تمت إضافة التلميذ",
    eleveMSA: "تم تعديل التلميذ",
    eleveSupp: "تم وضع التلميذ في الأرشيف",
    eleveSupp2: "تم حذف التلميذ ",
    eleveRective: "تم إعادة تفعيل التلميذ",

    parentAjout: "تمت إضافة الولي",
    parentMSA: "تم تعديل الولي",
    parentSupp: "تم وضع الولي في الأرشيف",

    eleveAjoutAuClasse: "تمت إضافة التلميذ إلى الفصل بنجاح!",
    eleveDemeangeAuClasse: "تم نقل التلميذ بنجاح إلى {name}",

    InscriptionAnnulee: "تم إلغاء التسجيل بنجاح.",

    imageTelecharge: "تم تحميل ملف {name} بنجاح.",

    conseillerPedagogiqueAjout: "تم إضافة المستشار التربوي",
    conseillerPedagogiqueMSA: "تم تعديل المستشار التربوي",
    conseillerPedagogiqueSupp: "تمت ارشفة المستشار التربوي",
  },

  error: {
    impoAjoutAppreciationOption: "غير قادر على إضافة ملاحظة",

    impoEnregistrerVide: "لا يمكن حفظ قيمة فارغة",
    invalidNote: "درجة غير صالحة !. يجب أن يكون بين 0 و 20",

    smsFormat: "تنسيق غير صالح ، يجب فصل أرقام الهواتف بفاصلات!",

    eleveDejaInscritPourMois: "التلميذ مسجل بالفعل بالأشهر المحددة",

    impoModifiEleve: "تعذر تحديث التلميذ",
    impoModifi: "تعذر تحديث ",
    eleveNonAjoutAuClasse:
      "التسجيل لم يتم إضافته برجاء الغاء التسجيل ثم تخصيص فصل جديد للتلميذ!",
    InscriptionNonModifi:
      "التسجيل لم يتم تعديله برجاء الغاء التسجيل ثم تخصيص فصل جديد للتلميذ!",

    matiereNonAffecte: "يجب إختيار مادة واحدة على الأقل!",
    matiereNonAffecte: "يجب ملئ كل المواد مع الضوارب الخاصة بها",
    subsubjectNonAffecte:
      "{subject} : يجب ملئ جميع المواد الفرعيّة مع الأعداد الخاصة بها ",

    capacite: "- سعة القاعة أقل من عدد الطلاب",
    fichiers: "يمكنك فقط تحميل ملفات JPG أو JPEG أو PNG!",
    classe: "الفصل غير موجود",
    activite: "النشاط غير موجود",
    service: "الخدمة غير موجودة",
    inscription: "تسجيل ",
    aucScol: "لم يتم العثور على تسجيل الرسوم الدراسية",
    aucClasse: "لم يتم العثور على الفصل",
    aucEleve: "لم يتم العثور على أي تلميذ",
    aucInscri: "لم يتم العثور على تسجيل للنشاط",
    aucPersonnel: "لم يتم العثور على موظفين",
    aucService: "لا توجد خدمة",
    aucActivite: "لم يتم العثور على أي نشاط",
    aucActualite: "لم يتم العثور على أخبار",
    aucRole: "لم يتم العثور على دور",
    aucEtablissement: "لم يتم العثور على مؤسسة",
    aucRecette: "لم يتم العثور على نوع الدخل",
    aucMatiere: "لم يتم العثور على أي مادة",
    aucUser: "لم يتم العثور على المستخدم",
    aucFournisseur: "لم يتم العثور على مورد",
    aucDepense: "لا توجد نفقات",
    aucQualification: "لم يتم العثور على نوع مؤهل",
    aucEnseignant: "لم يتم العثور على مدرس ",
    aucAnnee: "لم يتم العثور على العام الدراسي",
    aucSalle: "لم يتم العثور على القاعة",
    aucModule: "لم يتم العثور على الوحدات",

    verifier: "تحقق من كلمات المرور",
    modif: "خطأ في التحديث",
    ajout: "خطأ في الإضافة",
    supp: "خطأ في الحذف أو الأرشفة",

    impoInscriSupp: "غير قادر على حذف هذه القائمة",
    impoPubAjout: "غير قادر على إضافة آخرn",
    impoPubSupp: "غير قادر على حذف آخر",
    impoAjoutType: "غير قادر على إضافة هذا النوع",
    impoAjoutActivite: "غير قادر على إضافة النشاط",
    impoAjoutAnnee: "غير قادر على إضافة السنة الدراسية",
    impoAjoutClasse: "غير قادر على إضافة الفصل الدراسي",
    impoAjoutSalle: "غير قادر على إضافة القاعة",
    impoAjoutService: "غير قادر على إضافة الخدمة",
    impoAjoutFournisseur: "غير قادر على إضافة المزود",
    impoAjoutCeType: "غير قادر على إضافة هذ النوع",
    impoModifiMatieres: "غير قادر على تعديل المواد",
    impoModifiEnseignants: "غير قادر على تعديل المعلمين",

    image: "يجب أن تكون الصورة أقل من 5 ميغا بايت",
    images: "يجب أن تكون الصور أقل من 50 ميغا بايت",
    maxImage: "الحد الأقصى لعدد الصور هو 15",
    nonAjoute: "غير مضاف",

    autorisationNonMSA: "لم يتم تحديث التفويض",
    autorisationNonSupp: "لم تتم إزالة الصلاحية",

    eleveNonMSA: "لم يتم تحديث التلميذ",
    impoTelecharger: " تعذر تنزيل الصورة",

    dossier: "تعذر العثور على سجل التلميذ",
    dossierNonMSA: " لم يتم تحديث الملف",

    nonValid: "لا يمكنك التحقق من صحة دفع مبلغ يساوي صفر",

    recetteNonAjoute: "الدخل غير مضاف",
    recetteNonMSA: "لم يتم تحديث الدخل",
    recetteNonSupp: "لم يتم حذف الدخل",

    qualificationNonAjoute: "التأهيل غير مضاف",
    qualificationNonMSA: "لم يتم تحديث التأهيل",
    qualificationNonSupp: "لم تتم إزالة التأهيل",

    serviceNonSupp: "لم يتم حذف الخدمة",

    erreur: "حدث خطأ",

    depenseNonMSA: "لم يتم تحديث المصاريف",
    depenseNonSupp: "لم يتم حذف المصاريف",

    buildingEchoue: "{name} فشل تحديث المؤسسة",

    inscriEchoue: "فشل التسجيل",
    personnelNonAjoute: "الموظف غير مضاف ",
    personnelNonMSA: "لم يتم تحديث الموظف",

    enseignantNonAjoute: "لم تتم إضافة المعلم",
    enseignantNonMSA: "لم يتم تحديث المعلم",

    listeNonTrouve: "قائمة التلاميذ غير متوفرة!",

    anneeNonAjoute: "السنة الدراسية غير مضافة",
    anneeNonMSA: "لم يتم تحديث السنة الدراسية",

    punitionNonAjoute: "العقوبة غير مضافة",
    punitionNonMSA: "لم يتم تحديث العقوبة",

    serviceNonAjoute: "الخدمة غير مضافة",
    serviceNonMSA: "لم يتم تحديث الخدمة",

    depenseNonAjoute: "المصاريف غير مضافة ",
    depenseNonMSA: "لم يتم تحديث المصاريف",

    fournisseurNonAjoute: "المزود غير مضاف",
    fournisseurNonMSA: "لم يتم تحديث المزود",
    fournisseurNonSupp: "لم يتم حذف المزود",

    activiteNonMSA: "لم يتم تعديل النشاط، يرجى إعادة المحاولة",

    moduleNonAjoute: "لم يتم إضافة الوحدة",
    moduleNonMSA: "لم يتم تحديث الوحدة",
    moduleNonSupp: "لم يتم حذف الوحدة",
    personnelNonMSA: "لم يتم تحديث الموظف",
    personnelNonAjoute: "الموظف غير مضافين",
    matiereNonAjoute: "المادة غير مضافة",
    matiereNonMSA: "لم يتم تحديث المادة",
    listeNonTrouve: "قائمة التلاميذ غير متوفرة!",
    inscrEchoue: "فشل التسجيل",
    anneeNonSupp: "لا يمكن حذف السنة الدراسية",
    classeNonSupp: "لا يمكن حذف الفصل",
    classeIndis: "الفصل غير متوفر",
    salleIndis: "القاعة غير متوفرة ",
    enseignantIndis: "المعلم غير متوفر",
    selectEnseignant: "اختر مدرسًا",
    selectSeance: "إختر الحصة",
    selectClasse: "حدد فصل المدرسة",
    sallePriseEntre:
      "الغرفة {room} محجوزة لدى {name} مابين الساعة {start} إلى {end}",
    classPriseEntre: "تم حجز القسم {name} بين {start} و {end}",
    enseignantEnseigneClasse:
      "المعلم {fullname} يعلم الفصل {name} الموضوع {الموضوع} بين {start} و {end}",

    activiteNonSupp: "لا يمكن حذف النشاط ",

    utilisateurNonAjoutDiff:
      "لم تتم إضافة المستخدم ، يرجى المحاولة بإسم مستخدم مختلف!",

    conseillerPedagogiqueNonAjoute: "لم يتم إضافة المستشار التربويé",
    conseillerPedagogiqueNonMSA: "لم يتم تحديث المستشار التروبي",
    conseillerPedagogiqueNonSupp: "لم يتم حذف المستشار التربوي",
    invalidFormat: "تنسيق الملف غير مقبول",
  },
  warning: {
    verifieDonnees: "تحقق من البيانات",
    connectionProblem: "مشكلة في الاتصال ، يرجى تحديث الصفحة!",
    selectModePaiement: "اختار طريقة الدفع",
    paiementNonEffectuer: "لم يتم الدفع",
    invalidMarks: "يرجى التأكد من أن اعدادك بين 0 و 20",
    notCorrectMarks: "يرجى التأكد من إدخال اعدادك بشكل صحيح.",
    missedSubsubjects:
      "يبدو أنه لم يتم إكمال جميع الاعداد ({name}). يرجى إدخال جميع الاعداد المفقودة.",
  },
};

export default {
  locale: "ar-AR",
  localeAntd,
  messages,
};
