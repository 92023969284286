var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    cui__layout__grayBackground: _vm.settings.isGrayBackground,
  }},[_c('div',{staticStyle:{"position":"fixed","right":"-40px","top":"50%","z-index":"99","transform":"rotate(90deg)","padding-left":"-100px"}},[_c('a-button',{staticStyle:{"height":"50px"},attrs:{"type":"normal"},on:{"click":_vm.showDrawer}},[_c('br'),_c('a-icon',{attrs:{"type":"notification"}}),_vm._v("Feedback ")],1)],1),_c('a-drawer',{attrs:{"title":_vm.$t('feedback.sendFeedback'),"width":720,"visible":_vm.visible,"body-style":{ paddingBottom: '80px' }},on:{"close":_vm.onClose}},[_c('a-form',{attrs:{"form":_vm.form,"layout":"vertical","hide-required-mark":""}},[_c('a-row',[_c('a-col',[_c('a-form-item',{attrs:{"label":_vm.$t('feedback.category')}},[_c('a-checkbox-group',{attrs:{"options":[
                _vm.$t('feedback.comment'),
                _vm.$t('feedback.problem'),
                _vm.$t('feedback.idea'),
                _vm.$t('feedback.others') ]},on:{"change":function (a) {
                  _vm.feedback.categories = a;
                }},model:{value:(_vm.feedback.categories),callback:function ($$v) {_vm.$set(_vm.feedback, "categories", $$v)},expression:"feedback.categories"}})],1)],1)],1),(_vm.feedback.categories.includes(_vm.$t('feedback.problem')))?_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('feedback.section')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'section ',
                {
                  rules: [
                    { required: true, message: _vm.$t('feedback.selectSection') } ],
                } ]),expression:"[\n                'section ',\n                {\n                  rules: [\n                    { required: true, message: $t('feedback.selectSection') },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":_vm.$t('feedback.selectSection')},on:{"change":function (a) {
                  _vm.feedback.section = a;
                }}},[_vm._l((_vm.menuData),function(item,index){return [(!item.category)?_c('a-select-option',{key:index,attrs:{"value":_vm.$t(item.title)}},[_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")]):_vm._e()]})],2)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":_vm.$t('feedback.priority')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'priority',
                {
                  rules: [
                    {
                      required: true,
                      message: _vm.$t('feedback.selectPriority'),
                    } ],
                } ]),expression:"[\n                'priority',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: $t('feedback.selectPriority'),\n                    },\n                  ],\n                },\n              ]"}],attrs:{"placeholder":_vm.$t('feedback.selectPriority')},on:{"change":function (a) {
                  _vm.feedback.priority = a;
                }}},[_c('a-select-option',{attrs:{"value":"low"}},[_vm._v(" "+_vm._s(_vm.$t("feedback.low"))+" ")]),_c('a-select-option',{attrs:{"value":"medium"}},[_vm._v(" "+_vm._s(_vm.$t("feedback.medium"))+" ")]),_c('a-select-option',{attrs:{"value":"high"}},[_vm._v(" "+_vm._s(_vm.$t("feedback.high"))+" ")]),_c('a-select-option',{attrs:{"value":"critical"}},[_vm._v(" "+_vm._s(_vm.$t("feedback.critical"))+" ")])],1)],1)],1)],1):_vm._e(),_c('a-row',[_c('a-col',[_c('a-form-item',{attrs:{"label":_vm.$t('feedback.message')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'desc',
                {
                  rules: [{ required: true, message: _vm.$t('requis.message') }],
                } ]),expression:"[\n                'desc',\n                {\n                  rules: [{ required: true, message: $t('requis.message') }],\n                },\n              ]"}],attrs:{"placeholder":"Message","auto-size":{ minRows: 3, maxRows: 5 }},model:{value:(_vm.feedback.desc),callback:function ($$v) {_vm.$set(_vm.feedback, "desc", $$v)},expression:"feedback.desc"}})],1)],1)],1),_c('a-row',[_c('a-col',[_c('a-form-item',{attrs:{"label":_vm.$t('feedback.uploadImages')}},[_c('a-upload-dragger',{attrs:{"accept":".jpeg,.jpg,.png,.pdf","customRequest":_vm.uploadImage,"multiple":"","remove":_vm.removeFile,"before-upload":_vm.beforeUploadFiles}},[_c('p',{staticClass:"ant-upload-drag-icon"},[_c('a-icon',{attrs:{"type":"inbox"}})],1),_c('p',{staticClass:"ant-upload-text"},[_vm._v(" "+_vm._s(_vm.$t("actualite.cliquer"))+" ")])]),_c('a-progress',{directives:[{name:"show",rawName:"v-show",value:(_vm.uploadPercentage > 0),expression:"uploadPercentage > 0"}],staticClass:"mb-4",attrs:{"stroke-color":{
                from: '#108ee9',
                to: '#87d068',
              },"percent":_vm.uploadPercentage,"status":"active"}})],1)],1)],1),_c('a-row',[_c('a-col',[_c('a-form-item',{attrs:{"label":_vm.$t('feedback.uploadAudio')}},[_c('div',{staticClass:"d-flex flex-center mb-7"},[_c('div',{staticClass:"mt-2"},[(!_vm.recorder)?_c('a-button',{staticClass:"btn btn-info btn-sm",attrs:{"type":"primary"},on:{"click":_vm.onEnable}},[_c('a-icon',{attrs:{"type":"audio"}}),_vm._v(" "+_vm._s(_vm.$t("feedback.record"))+" ")],1):_vm._e(),(!_vm.isStarted && _vm.recorder)?_c('a-button',{attrs:{"size":"large","shape":"circle"},on:{"click":function () {
                      _vm.timer = _vm.moment(0);
                      _vm.onStart();
                    }}},[_c('a-icon',{attrs:{"type":"audio"}})],1):_vm._e(),(_vm.isStarted)?_c('a-button',{attrs:{"size":"large","type":"danger","shape":"circle"},on:{"click":_vm.onStop}},[_c('a-icon',{staticClass:"pt-2",attrs:{"type":"check"}})],1):_vm._e(),(_vm.isStarted)?_c('span',{staticClass:"pl-1 pt-3"},[_vm._v(" "+_vm._s(_vm.counter)+" ")]):_vm._e()],1),_c('br'),_c('br'),(_vm.stream && !_vm.isStarted)?_c('div',{staticStyle:{"margin-left":"10px","margin-right":"3px"}},[_c('audio',{attrs:{"controls":"true","src":_vm.stream}})]):_vm._e()])])],1)],1)],1),_c('div',{style:({
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      })},[_c('a-button',{style:({ marginRight: '8px' }),on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.$t("action.annuler"))+" ")]),_c('a-button',{attrs:{"loading":_vm.feedbackLoading,"disabled":_vm.feedbackLoading,"type":"primary"},on:{"click":function () {
            _vm.sendFeedback();
          }}},[_vm._v(" "+_vm._s(_vm.$t("feedback.sendFeedback"))+" ")])],1)],1),_c('a-layout',{class:{
      cui__layout__contentMaxWidth: _vm.settings.isContentMaxWidth,
      cui__layout__appMaxWidth: _vm.settings.isAppMaxWidth,
      cui__layout__grayBackground: _vm.settings.isGrayBackground,
      cui__layout__squaredBorders: _vm.settings.isSquaredBorders,
      cui__layout__cardsShadow: _vm.settings.isCardShadow,
      cui__layout__borderless: _vm.settings.isBorderless,
    }},[_c('cui-sidebar'),(_vm.settings.menuLayoutType === 'left' && !_vm.settings.isMobileView)?_c('cui-menu-left'):_vm._e(),(_vm.settings.isMobileView)?_c('div',[_c('div',{class:_vm.$style.handler,on:{"click":_vm.toggleMobileMenu}},[_c('div',{class:_vm.$style.handlerIcon})]),_c('a-drawer',{attrs:{"closable":false,"visible":_vm.settings.isMobileMenuOpen,"placement":"left","wrapClassName":_vm.$style.mobileMenu},on:{"close":_vm.toggleMobileMenu}},[_c('cui-menu-left')],1)],1):_vm._e(),(_vm.settings.menuLayoutType === 'top' && !_vm.settings.isMobileView)?_c('cui-menu-top'):_vm._e(),_c('a-layout',[_c('a-layout-header',{class:{
          cui__layout__header: true,
          cui__layout__fixedHeader: _vm.settings.isTopbarFixed,
          cui__layout__headerGray: _vm.settings.isGrayTopbar,
        }},[_c('cui-topbar')],1),(this.$route.path != '/chat')?_c('cui-breadcrumbs'):_vm._e(),_c('a-layout-content',{staticStyle:{"height":"'100%'","position":"'relative'"}},[_c('div',{staticClass:"cui__utils__content"},[_c('transition',{key:_vm.reloads,attrs:{"name":_vm.settings.routerAnimation,"mode":"out-in"},on:{"reload":_vm.reload}},[_c('router-view')],1)],1)]),_c('footer-layout',{attrs:{"mainTheme":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }